import { css } from '@mui/system';
export const image = css`
  height: 35px;
  margin-bottom: 5px;
`;
export const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
  }
  & > img {
  }
  & > h4 {
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 8px;
  }
`;

export const container = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 25px 0;
  grid-gap: 15px;

  // ipad air horizontal
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'honorific honorific'
      'firstName lastName'
      'phone phone'
      'email email'
      'dateOfBirth dateOfBirth'
      'zipCode zipCode'
      'sex sex';
  }
`;

export const successWrapper = css`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;

  div {
    display: grid;

    grid-template-areas:
      'heading heading'
      'text image';
    width: 450px;
  }

  h2,
  p {
    font-family: Poppins;
  }

  h2 {
    grid-area: heading;
    font-weight: 600;
  }
  p {
    grid-area: text;
    text-align: left;
  }
  img {
    grid-area: image;
  }
`;

export const missingWrapper = css`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4 {
    font-family: Poppins;
    font-weight: 400;
  }

  img {
    width: 300px;
  }
`;
