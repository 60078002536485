import { css, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers-pro';
import React from 'react';
import { getDateValue } from './common';

const hidePickerCss = css`
  visibility: hidden;
  position: absolute;
  width: 0;
`;
interface DatePickerWrapperProps
  extends Omit<DesktopDatePickerProps<Date>, 'value'>,
    Pick<TextFieldProps, 'required' | 'helperText' | 'InputLabelProps'> {
  inputVariant?: TextFieldProps['variant'];
  inputProps?: TextFieldProps;
  hidden?: boolean;
  value?: string | number | Date;
}

export const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({
  required,
  inputVariant,
  helperText,
  InputLabelProps,
  hidden,
  inputProps,
  value,
  ...datePickerProps
}) => {
  return (
    <DesktopDatePicker
      data-testid={`datePickerWrapper-${datePickerProps.label}`}
      slotProps={{
        textField: {
          label: datePickerProps.label,
          variant: inputVariant,
          helperText,
          required,
          InputLabelProps,
          ...inputProps,
        },
      }}
      value={getDateValue(value)}
      css={hidden ? hidePickerCss : undefined}
      {...datePickerProps}
    />
  );
};
