import { css } from '@mui/system';
import { orderBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ExternalAppointment, ExternalAppointmentStatus, ExternalAppointmentType, PlaceOfService } from '@laguna/api';
import { FieldsType, FieldType } from '../formTypes';
import { generateForm } from '../generateForm';

interface ExternalAppointmentEditorProps {
  value: ExternalAppointment;
  onChange: (med: ExternalAppointment) => void;
}

const sortedExternalTypes = orderBy(ExternalAppointmentType, (a) => a.toLowerCase()).reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {}
);

const fields: FieldsType[] = [
  {
    name: 'status',
    label: 'medicalFields:externalAppointment:status',
    type: FieldType.select,
    values: ExternalAppointmentStatus,
    nullable: false,
  },
  {
    name: 'placeOfService',
    label: 'medicalFields:externalAppointment:placeOfService',
    type: FieldType.select,
    values: PlaceOfService,
  },
  {
    name: 'type',
    label: 'medicalFields:externalAppointment:type',
    type: FieldType.select,
    values: sortedExternalTypes,
  },
  { name: 'drName', label: 'medicalFields:externalAppointment:provider', type: FieldType.text },
  { name: 'clinic', label: 'medicalFields:externalAppointment:clinic', type: FieldType.text },
  { name: 'fullAddress', label: 'medicalFields:externalAppointment:fullAddress', type: FieldType.text },
  { name: 'phone', label: 'medicalFields:externalAppointment:phone', type: FieldType.phone },
  { name: 'fax', label: 'medicalFields:externalAppointment:fax', type: FieldType.phone },
  { name: 'specialInstructions', label: 'medicalFields:externalAppointment:specialInstructions', type: FieldType.text },
  {
    name: 'frequency',
    label: 'Frequency',
    type: FieldType.frequency,
    allowPast: true,
    defaultValue: {
      cronExpressions: undefined,
      start: undefined,
    },
  },
];

interface ExternalAppointmentForm extends ExternalAppointment {
  frequency?: {
    start: Date;
    end?: Date;
    cronExpressions?: string[];
  };
}

const formCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const addFrequency = (appt: ExternalAppointment): ExternalAppointmentForm => ({
  ...appt,
  frequency: appt.start
    ? {
        start: appt.start,
        end: appt.end,
        cronExpressions: appt.cronExpressions,
      }
    : undefined,
});

export const ExternalAppointmentEditor: React.FC<ExternalAppointmentEditorProps> = ({ value, onChange }) => {
  const { Form } = useMemo(
    () =>
      generateForm<ExternalAppointmentForm>({
        formId: 'createExternalAppointment',
        fields,
        useOnlyDirtyFields: false,
        allowNoChangesSubmit: true,
      }),
    []
  );

  const onFormChange = useCallback((val: ExternalAppointmentForm) => {
    const { frequency, ...rest } = val;
    onChange({ ...rest, start: frequency?.start, end: frequency?.end, cronExpressions: frequency?.cronExpressions });
  }, []);
  return (
    <Form css={formCSS} isLoading={false} initialData={addFrequency(value)} onChange={onFormChange} useDivAsContainer />
  );
};
