import {
  AutocompleteProps,
  ButtonProps,
  InputBaseComponentProps,
  InputLabelProps,
  TypographyProps,
} from '@mui/material';
import { HTMLInputTypeAttribute, RefObject } from 'react';
import { Control, FieldValues, UseFormSetValue, Validate, ValidationMode } from 'react-hook-form';

export enum FieldType {
  text = 'text',
  date = 'date',
  select = 'select',
  asyncSelect = 'asyncSelect',
  dynamicSelect = 'dynamicSelect',
  rating = 'rating',
  email = 'email',
  password = 'password',
  passwordStrength = 'passwordStrength',
  phone = 'phone',
  title = 'title',
  choice = 'choice',
  checkbox = 'checkbox',
  radio = 'radio',
  range = 'range',
  divider = 'divider',
  groupHeader = 'groupHeader',
  dateTime = 'dateTime',
  asyncAutocomplete = 'asyncAutocomplete',
  autocomplete = 'autocomplete',
  subForm = 'subForm',
  frequency = 'frequency',
  medication = 'medication',
  externalAppointment = 'externalAppointment',
  switch = 'switch',
  file = 'file',
  orderedList = 'orderedList',
}
type IsAny<T> = 0 extends 1 & T ? true : false;
type IfAny<T, Y, N> = IsAny<T> extends true ? Y : N;

interface BasicField<FieldType = any, FormType = any> {
  name: IfAny<FormType, string, keyof FormType>;
  label: string;
  required?: boolean | string;
  autoFocus?: boolean;
  defaultValue?: any;
  rowId?: string;
  isHidden?: boolean;
  disabled?: boolean;
  validate?: Validate<any>;
  getDerivedState?: (
    updatedFields: Partial<FormType>,
    updatedFieldName?: string,
    setValue?: UseFormSetValue<FieldValues>,
    initialData?: Partial<any>
  ) => Partial<FieldType> | Promise<Partial<FieldType>>;
}

export interface SharedFieldProps extends Omit<BasicField, 'rowId' | 'getDerivedState'>, WithInputVariant {
  control: Control<FieldValues, object>;
  formId: string;
  testId?: string;
  validate?: Validate<string>;
}
export interface ChoiceOption {
  label: string;
  value: number | string;
}

export interface ChoiceRefOption {
  option: string;
  ref?: RefObject<any>;
}

interface GroupHeader<FormType = any> extends BasicField<GroupHeader, FormType> {
  type: FieldType.groupHeader;
  options: ChoiceOption[];
}
interface Title<FormType = any> extends BasicField<Title, FormType> {
  type: FieldType.title;
  variant?: 'title' | 'subtitle' | 'label' | 'caption';
  fontWeight?: number;
  typographyProps?: TypographyProps;
}
interface Divider<FormType = any> extends BasicField<Divider, FormType> {
  type: FieldType.divider;
  strong?: boolean;
}
export interface ChoiceField<FormType = any> extends BasicField<ChoiceField, FormType> {
  type: FieldType.choice;
  readOnly?: boolean;
  isColored?: boolean;
  options: ChoiceOption[];
}

export interface CheckboxField<FormType = any> extends BasicField<CheckboxField, FormType> {
  type: FieldType.checkbox;
  readOnly?: boolean;
  options: string[] | { option: string; ref?: RefObject<any> }[] | ChoiceOption[];
  transformValues?: (key: string) => string;
}

export interface FilePicker<FormType = any> extends BasicField<FilePicker, FormType> {
  type: FieldType.file;
  accept?: HTMLInputElement['accept'];
}

interface WithInputLabelProps {
  InputLabelProps?: InputLabelProps;
}
interface WithPlaceholder {
  placeholder?: string;
}
interface WithInputLimit {
  maxLength?: number;
}
interface WithInputVariant {
  variant?: 'outlined' | 'filled' | 'standard';
}

interface WithDisableAutofill {
  disableAutofill?: boolean;
}

export function isWithInputVariant(object: any): object is WithInputVariant {
  return 'variant' in object;
}

interface TextField<FormType = any>
  extends BasicField<TextField, FormType>,
    WithInputLimit,
    WithInputVariant,
    WithDisableAutofill,
    WithInputLabelProps,
    WithPlaceholder {
  type: FieldType.text;
  inputType?: HTMLInputTypeAttribute;
  width?: string;
  inputProps?: InputBaseComponentProps;
  rows?: number;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
}

interface DateField<FormType = any> extends BasicField<DateField, FormType>, WithInputVariant {
  type: FieldType.date;
  disableFuture?: boolean;
  maxDate?: Date;
}
interface PasswordStrength<FormType = any> extends BasicField<PasswordStrength, FormType> {
  type: FieldType.passwordStrength;
  passwordFieldName: string;
}

interface DateTimeField<FormType = any> extends BasicField<DateTimeField, FormType> {
  type: FieldType.dateTime;
}

interface EmailField<FormType = any>
  extends BasicField<EmailField<FormType>, FormType>,
    WithInputLimit,
    WithInputVariant,
    WithDisableAutofill {
  type: FieldType.email;
}
interface PasswordField<FormType = any>
  extends BasicField<PasswordField, FormType>,
    WithInputLimit,
    WithInputVariant,
    WithDisableAutofill,
    WithInputLabelProps {
  type: FieldType.password;
}
interface PhoneField<FormType = any>
  extends BasicField<PhoneField, FormType>,
    WithInputLimit,
    WithInputVariant,
    WithDisableAutofill {
  type: FieldType.phone;
  prefix?: string;
  hideCountryCode?: boolean;
}
export interface SelectField<FormType = any> extends BasicField<SelectField<FormType>, FormType>, WithInputVariant {
  type: FieldType.select;
  values: Record<string, string>;
  nullable?: boolean;
  multiple?: boolean;
  onChange?: (value: string) => void;
  transformValues?: (key: string) => string;
}
export interface RadioField<FormType = any> extends BasicField<RadioField, FormType> {
  type: FieldType.radio;
  values: Record<string, string>;
  transformValues?: (val: string) => string;
  direction?: 'column' | 'row' | 'fullRow';
  getTooltipForValue?: (value: string) => string | undefined;
}

export interface AsyncAutocompleteField<FormType = any>
  extends BasicField<AsyncAutocompleteField, FormType>,
    Pick<AutocompleteProps<any, boolean, boolean, boolean>, 'renderOption'> {
  type: FieldType.asyncAutocomplete;
  fetchItems: (query: string | null) => Promise<string[]>;
  fetchOnMount?: boolean;
  groupBy?: (option: string) => string;
  getOptionDisabled?: (option: string) => boolean;
}

export interface AutocompleteField<FormType = any>
  extends BasicField<AutocompleteField, FormType>,
    Omit<AutocompleteProps<any, boolean, boolean, boolean>, 'renderInput'> {
  type: FieldType.autocomplete;
}
export interface FrequencySelectField<FormType = any> extends BasicField<FrequencySelectField, FormType> {
  type: FieldType.frequency;
  allowPast?: boolean;
}
export interface MedicationField<FormType = any> extends BasicField<MedicationField, FormType> {
  type: FieldType.medication;
}
export interface ExternalAppointmentField<FormType = any> extends BasicField<ExternalAppointmentField, FormType> {
  type: FieldType.externalAppointment;
}

export interface AsyncSelectField extends BasicField {
  type: FieldType.asyncSelect;
  query: any;
  queryKey?: string;
  valuesKey: string;
  multiple?: boolean;
}

export interface DynamicSelectField extends BasicField {
  type: FieldType.dynamicSelect;
  fetchItems: (query: string | null) => Promise<Record<string, string>>;
}

interface RangeField<FormType = any> extends BasicField<RangeField, FormType> {
  type: FieldType.range;
  min: {
    value: number;
    label: string;
  };
  max: {
    value: number;
    label: string;
  };
  nullable?: boolean;
}
export interface SubFormField extends BasicField<SubFormField> {
  type: FieldType.subForm;
  fields: FieldsType[];
  useGrid?: boolean;
  ref?: RefObject<any>;
}

export interface SwitchField<FormType = any> extends BasicField<SwitchField, FormType> {
  type: FieldType.switch;
  value?: boolean;
  validateOnChange?: (defaultVal: boolean, newVal: boolean) => boolean;
  description?: string;
}

export interface OrderedListField extends BasicField {
  type: FieldType.orderedList;
  fields: FieldsType[];
}

export type FieldsType<FormType = any> =
  | TextField<FormType>
  | DateField<FormType>
  | DateTimeField<FormType>
  | SelectField<FormType>
  | EmailField<FormType>
  | PasswordField<FormType>
  | PasswordStrength<FormType>
  | AsyncSelectField
  | PhoneField<FormType>
  | ChoiceField<FormType>
  | RadioField<FormType>
  | RangeField<FormType>
  | Divider<FormType>
  | GroupHeader<FormType>
  | Title<FormType>
  | CheckboxField<FormType>
  | DynamicSelectField
  | SubFormField
  | AsyncAutocompleteField<FormType>
  | FrequencySelectField<FormType>
  | MedicationField<FormType>
  | ExternalAppointmentField<FormType>
  | SwitchField<FormType>
  | FilePicker<FormType>
  | AutocompleteField<FormType>
  | OrderedListField;

export interface FormProps<T> {
  onSubmit?: (data: Partial<T>) => Promise<void>;
  isLoading: boolean;
  initialData: Partial<T>;
  className?: string;
  disabled?: boolean;
  error?: string;
  useDivAsContainer?: boolean;
  onChange?: (data: T) => void;
}

export interface AdditionalButton {
  label: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
}
export interface ButtonsProps {
  isLoading: boolean;
  onCancel?: () => void;
  onReset?: () => void;
  submitLabel?: string;
  cancelLabel?: string;
  resetLabel?: string;
  additionalButtons?: AdditionalButton[];
  submitButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  resetButtonProps?: ButtonProps;
  buttonsClassName?: string;
}

export interface generateFormArgs {
  formId: string;
  fields: FieldsType[];
  useOnlyDirtyFields?: boolean;
  allowNoChangesSubmit?: boolean;
  useGrid?: boolean;
  enableButtonsOnValid?: boolean;
  validationMode?: keyof ValidationMode;
}

export interface GridAreaComponent {
  ['grid-area']?: string;
}

export interface PhoneCountryCode {
  phoneCountryCode?: string;
}
