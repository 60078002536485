import { AddCaregiverParams, CreateMemberParams } from '@laguna/api';
import { logger } from '@laguna/logger';
import { Checkbox, FormControlLabel, FormHelperText, Link, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import defaultLogo from '../../assets/logo_laguna.png';
import { getOrgData, register, registerCaregiver } from '../api/api';
import { extractCaregiverParams, extractCreateMemberParams, useRegisterForm } from '../registerFormUtils';
import { OrgData, RegisterFormData } from '../types';
import { container, image, wrapper } from './styles';

export const Register: React.FC = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const { orgId } = useParams<{ orgId: string }>();
  const { Form, Buttons, defaultValues } = useRegisterForm(orgId);
  const [orgData, setOrgData] = useState<OrgData>();
  const [isLoading, setIsLoading] = useState(false);
  const [userConsent, setUserConsent] = useState(false);

  const navigate = useNavigate();
  const initOrg = async () => {
    try {
      if (!orgId) {
        throw new Error('missing data');
      }
      const orgData = await getOrgData(orgId);
      if (!orgData) {
        throw new Error('missing data');
      }
      setOrgData(orgData);
    } catch (e) {
      logger.error('Could not get data for org', { orgId });
      navigate('/missingOrg');
    }
  };

  useEffect(() => {
    initOrg();
  }, []);

  const onSubmit = useCallback(
    async (submittedFormData: Partial<RegisterFormData>) => {
      const formData: Partial<RegisterFormData> = {
        ...defaultValues,
        ...submittedFormData,
      };
      if (orgData?.userConsentRequired && !userConsent) {
        setErrorMessage('User consent needed');
        return;
      }

      setIsLoading(true);
      try {
        ['email', 'honorific'].forEach((deleteAbleField: string) => {
          if (!formData[deleteAbleField as keyof RegisterFormData]) {
            delete formData[deleteAbleField as keyof RegisterFormData];
          }
        });
        const createMemberParams: CreateMemberParams = extractCreateMemberParams(
          formData as RegisterFormData,
          orgData?.id
        );
        const { id: memberId } = await register(createMemberParams);
        if (formData.caregiver_toggle?.length) {
          const createCaregiverParams: AddCaregiverParams = extractCaregiverParams(
            formData as RegisterFormData,
            memberId
          );
          await registerCaregiver(createCaregiverParams);
        }
        navigate('/success');
      } catch (e: any) {
        const isServerInfoError = typeof e.message === 'object'; //an object;
        setErrorMessage(isServerInfoError ? e.message?.[0] : t('registerErrorMessage'));
        setIsLoading(false);
      }
    },

    [orgData, userConsent, defaultValues]
  );

  return (
    <div css={wrapper}>
      <img alt='org-logo' data-testid='register-org-logo' src={orgData?.logo || defaultLogo} css={image} />
      <Typography variant='h4'>{t('registerTitle')}</Typography>
      <Form initialData={defaultValues} onSubmit={onSubmit} isLoading={isLoading} css={container} />
      {orgData?.userConsentRequired && (
        <FormControlLabel
          control={<Checkbox value={userConsent} onChange={(e) => setUserConsent(e.target.checked)} />}
          label={
            <Trans
              i18nKey='userConsent'
              components={{
                a: <Link href={t('privacyLink')} target='_blank' />,
              }}
            />
          }
        />
      )}
      <Buttons isLoading={isLoading} submitButtonProps={{ variant: 'contained', size: 'large', color: 'secondary' }} />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
