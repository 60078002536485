import { FCC } from '@laguna/types';
import { Card, CardContent } from '@mui/material';
import { css } from '@mui/system';

const background = css`
  background: linear-gradient(#df4ad3, #7233e2);
  height: 100vh;
  display: flex;
  align-items: baseline;
  padding: 55px;
  justify-content: center;
  overflow: scroll;

  & > div {
    width: 1180px;
  }

  // ipad air horizontal
  @media screen and (max-width: 1180px) {
    background: white;
    align-items: center;
    padding: 0 55px;

    & > div {
      box-shadow: none;
    }
  }
  @media screen and (max-width: 1180px) and (orientation: portrait) {
    align-items: baseline;
    padding: 55px;
  }
`;

export const AppStyledWrapper: FCC = ({ children }) => (
  <div css={background}>
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  </div>
);
