import { AddCaregiverParams, CreateMemberParams } from '@laguna/api';

const baseUrl = process.env.NX_GQL_ENDPOINT;

export const register = async (data: CreateMemberParams): Promise<{ id: string }> => {
  const response = await fetch(`${baseUrl}/api/members/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData;
  }

  return await response.json();
};

export const registerCaregiver = async (data: AddCaregiverParams) => {
  const response = await fetch(`${baseUrl}/api/caregivers/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData;
  }

  return await response.json();
};

export const getOrgData = async (orgCode: string) => {
  const res = await fetch(`${baseUrl}/api/orgs/details/${orgCode}`);
  return res.json();
};
