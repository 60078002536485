import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { FCC } from '@laguna/types';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useMemo } from 'react';
import useSettings from './hooks/useSettings';

export type ThemeProviderProps = {
  lockLightTheme?: boolean;
  getCustomTheme?: (isLight: boolean) => Theme;
  getComponentOverrides?: (theme: Theme) => Theme['components'];
};

export const ThemeProvider: FCC<ThemeProviderProps> = ({
  children,
  lockLightTheme,
  getCustomTheme,
  getComponentOverrides,
}) => {
  const { isLight } = useSettings();
  const theme: Theme = useMemo(() => {
    const shouldUseLight = isLight || lockLightTheme;
    const current = getCustomTheme?.(!!shouldUseLight) || createTheme();
    if (getComponentOverrides) {
      current.components = getComponentOverrides(current);
    }

    return current;
  }, [isLight, lockLightTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </EmotionThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
