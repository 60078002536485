import { AddCaregiverParams, CreateMemberParams, Honorific, Language, Relationship, Sex } from '@laguna/api';
import { FieldType, FieldsType } from '@laguna/common/form/generateForm';
import { useMemoForm } from '@laguna/common/form/useMemoForm';
import { differenceInYears } from 'date-fns';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { RegisterFormData } from './types';

const nameValidation = (name: string) => {
  if (name.length < 2 || name.length > 50) {
    return 'Field should be between 2 and 50 characters';
  }
  return undefined;
};

const dateOfBirthValidation = (date: Date) => {
  if (differenceInYears(new Date(), date) < 18) {
    return 'Age must be 18+';
  }
  return undefined;
};
const getShowIfCaregiverEnables = (required: boolean) => (fields: Partial<RegisterFormData>) => {
  return {
    isHidden: !fields.caregiver_toggle?.length,
    required: required && !!fields.caregiver_toggle?.length,
  };
};
const showIfCaregiverEnables = getShowIfCaregiverEnables(true);
const showIfCaregiverEnablesNotRequired = getShowIfCaregiverEnables(false);
const changeHonorificOnGender = (fields: Partial<RegisterFormData>, fieldName: string | undefined, setValue) => {
  if (fieldName === 'sex') {
    switch (fields.sex) {
      case Sex.male:
        setValue?.('honorific', Honorific.mr);
        break;
      case Sex.female:
        setValue?.('honorific', Honorific.ms);
        break;
    }
  }
  return {};
};
const updateMGHFields = (item: FieldsType<RegisterFormData>): FieldsType<RegisterFormData> => {
  switch (item.name) {
    case 'honorific': {
      return { ...item, getDerivedState: changeHonorificOnGender };
    }
    case 'zipCode': {
      return { ...item, isHidden: true };
    }
    default: {
      return item;
    }
  }
};

const caregiverFields: FieldsType<RegisterFormData>[] = [
  {
    type: FieldType.divider,
    name: 'caregiver_div',
    label: '',
  },
  {
    type: FieldType.checkbox,
    name: 'caregiver_toggle',
    label: '',
    options: [{ label: 'Caregiver', value: 'true' }],
  },
  {
    type: FieldType.text,
    name: 'caregiver_firstName',
    label: 'Caregiver First Name',
    required: true,
    variant: 'standard',
    getDerivedState: showIfCaregiverEnables,
    rowId: 'caregiver_details',
  },
  {
    type: FieldType.text,
    name: 'caregiver_lastName',
    label: 'Caregiver Last Name',
    required: true,
    variant: 'standard',
    getDerivedState: showIfCaregiverEnables,
    rowId: 'caregiver_details',
  },
  {
    type: FieldType.select,
    values: Relationship,
    name: 'caregiver_relationship',
    label: 'Relationship',
    required: false,
    variant: 'standard',
    getDerivedState: showIfCaregiverEnablesNotRequired,
    rowId: 'caregiver_details',
  },
  {
    type: FieldType.phone,
    name: 'caregiver_phone',
    label: 'Caregiver Mobile Phone',
    required: true,
    getDerivedState: showIfCaregiverEnables,
    variant: 'standard',
    hideCountryCode: true,
  },
  {
    type: FieldType.date,
    name: 'caregiver_dateOfBirth',
    label: 'Caregiver Date of Birth',
    required: true,
    variant: 'standard',
    validate: dateOfBirthValidation,
    getDerivedState: showIfCaregiverEnables,
  },
];
const commonFields: FieldsType<RegisterFormData>[] = [
  {
    type: FieldType.select,
    name: 'honorific',
    label: 'Title',
    nullable: false,
    values: Honorific,
    variant: 'standard',
    rowId: 'details',
  },
  {
    type: FieldType.text,
    name: 'firstName',
    label: 'First Name',
    required: true,
    variant: 'standard',
    rowId: 'details',
    validate: nameValidation,
  },
  {
    type: FieldType.text,
    name: 'lastName',
    label: 'Last Name',
    required: true,
    variant: 'standard',
    rowId: 'details',
    validate: nameValidation,
  },
  {
    type: FieldType.phone,
    name: 'phone',
    label: 'Mobile Phone',
    required: true,
    variant: 'standard',
    hideCountryCode: true,
  },
  { type: FieldType.email, name: 'email', label: 'Email', variant: 'standard' },
  {
    type: FieldType.date,
    name: 'dateOfBirth',
    label: 'Date of Birth',
    required: true,
    variant: 'standard',
    validate: dateOfBirthValidation,
  },
  { type: FieldType.text, name: 'zipCode', label: 'Zip Code', required: true, variant: 'standard' },
  { type: FieldType.radio, name: 'sex', label: 'Gender', required: true, values: Sex, direction: 'row' },
  ...caregiverFields,
];

const commonDefaultValues: Partial<RegisterFormData> = { sex: Sex.male };

const getOrgFields = (orgCode: string): { fields: FieldsType[]; defaultValues: Partial<RegisterFormData> } => {
  switch (orgCode) {
    case 'mayo':
      return {
        fields: commonFields.filter((field) => !field.name.startsWith('caregiver')),
        defaultValues: commonDefaultValues,
      };
    case 'mgh':
      return {
        fields: commonFields.map(updateMGHFields).filter((field) => field.name !== 'email'),
        defaultValues: {
          ...commonDefaultValues,
          zipCode: '02114',
          honorific: Honorific.mr,
        },
      };
    default:
      return { fields: commonFields, defaultValues: commonDefaultValues };
  }
};

export const useRegisterForm = (orgCode = '') => {
  const { fields, defaultValues } = useMemo(() => getOrgFields(orgCode), [orgCode]);

  const { Form, Buttons } = useMemoForm({ formId: 'register', fields, useOnlyDirtyFields: false, useGrid: true });
  return { Form, Buttons, defaultValues };
};

const language = Language.en;

export const extractCreateMemberParams = (
  {
    caregiver_toggle,
    caregiver_firstName,
    caregiver_lastName,
    caregiver_relationship,
    caregiver_dateOfBirth,
    caregiver_phone,
    dateOfBirth,
    ...data
  }: RegisterFormData,
  orgId = ''
): CreateMemberParams => ({
  ...data,
  language,
  dateOfBirth: format(dateOfBirth, 'yyyy/MM/dd'),
  orgId,
});

export const extractCaregiverParams = (data: RegisterFormData, memberId: string): AddCaregiverParams => ({
  relationship: data.caregiver_relationship || Relationship.other,
  lastName: data.caregiver_lastName,
  firstName: data.caregiver_firstName,
  phone: data.caregiver_phone,
  memberId,
  dateOfBirth: format(data.caregiver_dateOfBirth, 'yyyy/MM/dd'),
});
