import { replaceItem } from '@laguna/common/utils/arrayUtils';
import { logger } from '@laguna/logger';
import { QueryKey } from '@tanstack/react-query';
import { queryClient } from '../../utils/getQueryResult';
import { differenceInSeconds } from 'date-fns';
import { SocketHandler, SubscriptionSocketMessage } from './types';

const QUERIES_WITH_UPDATE_AS_REPLACE = ['getMemberChatListForUser'];
const SIMILAR_EVENT_THRESHOLD_IN_SECONDS = 10;

interface AlertEventType {
  id: string;
  type: string;
  text: string;
  date: string;
  memberId: string;
}

const SPLIT_CHAR = '@/@';

export const getQueryKey = (key?: string | null): QueryKey | null => {
  if (!key) {
    return null;
  }
  const split = key.split(SPLIT_CHAR);
  const variables = split[1] ? JSON.parse(split[1]) : null;
  return variables ? [split[0], variables] : [split[0]];
};

const isAlertEvent = (value: any): value is AlertEventType => {
  return !!value.date && !!value.text && !!value.type && !!value.memberId;
};
const getIsSamePredicate = (target: any, queryKey: QueryKey) => {
  if (isAlertEvent(target)) {
    const targetDate = new Date(target.date);

    return (cacheItem: any) => {
      if (isAlertEvent(cacheItem)) {
        return (
          target.text === cacheItem.text &&
          target.type === cacheItem.type &&
          Math.abs(differenceInSeconds(new Date(cacheItem.date), targetDate)) < SIMILAR_EVENT_THRESHOLD_IN_SECONDS
        );
      }
      return cacheItem.id === target.id;
    };
  }
  return (cacheItem: any) => cacheItem.id === target.id;
};

const buildNewArray = (isDelete: boolean, payload: any, queryKey: QueryKey, oldData?: any) => {
  if (isDelete) {
    logger.debug('update cache called, delete item');
    return oldData?.filter(({ id }: any) => id !== payload.id);
  }
  if (!oldData) {
    logger.warn('socket message without old data', { payload, isDelete });
  }

  const isSamePredicate = getIsSamePredicate(payload, queryKey || '');
  const oldItemIndex = oldData.findIndex(isSamePredicate);

  if (QUERIES_WITH_UPDATE_AS_REPLACE.includes(queryKey[0] as string)) {
    return payload;
  }
  if (oldItemIndex > -1) {
    logger.debug('update cache called, update item');
    return replaceItem(oldData, oldItemIndex, payload);
  } else {
    logger.debug('update cache called, add item');
    return oldData ? [...oldData, payload] : [payload];
  }
};

const buildNewObject = (isDelete: boolean, payload: any, queryKey: QueryKey, oldData?: any) => {
  if (isDelete) {
    logger.warn('try to delete object data, not supported');
    return oldData;
  }
  if (payload && oldData?.id === payload.id) {
    logger.debug('update cache called, update item');
    return payload;
  } else {
    logger.debug('update cache called, add item');
    return oldData;
  }
};
export const buildNewCacheData = (queryKey: QueryKey, data: SubscriptionSocketMessage) => (oldData: any) => {
  const hasCache = queryClient.getQueryState(queryKey);
  if (!hasCache) {
    logger.warn('update cache called for item not in cache', { queryKey });
    return;
  }
  const { payload, metadata } = data;
  const queryName = queryKey[0] as string;
  const isDelete = metadata?.operation === 'deleted';
  logger.debug('update cache called for key:', { queryKey });
  const method = Array.isArray(oldData?.[queryName]) ? buildNewArray : buildNewObject;
  return { [queryName]: method(isDelete, payload, queryKey, oldData?.[queryName]) };
};

export const handleSubscriptionSocketMessage: SocketHandler = async (data: SubscriptionSocketMessage) => {
  const { invalidate, subscriptionKey } = data.metadata;

  const queryKey = getQueryKey(subscriptionKey);

  if (!queryKey) {
    logger.warn('update cache called with invalid queryKey', { queryKey, subscriptionKey });
    return;
  }
  if (invalidate) {
    logger.debug('update cache called with invalidate=true', { queryKey });
    queryClient.invalidateQueries(queryKey);
    return;
  }

  queryClient.setQueryData(queryKey, buildNewCacheData(queryKey, data));
};
