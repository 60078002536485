import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { successWrapper } from './styles';
import success from '../../assets/SuccessIllustration.png';

export const Success: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='page successPage' css={successWrapper}>
      <div>
        <Typography variant='h2' className='successHeader'>
          {t('successHeader')}
        </Typography>
        <Typography className='successText'>{t('successText')}</Typography>
        <img className='successImg' src={success} alt='success' />
      </div>
    </div>
  );
};
