import { FCC } from '@laguna/types';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { SettingsProvider } from './contexts/SettingsContext';
import { ThemeProvider, ThemeProviderProps } from './ThemeProvider';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.NX_MUI_KEY || '');

export const ThemeAppWrapper: FCC<ThemeProviderProps> = ({ children, ...props }) => (
  <SettingsProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider {...props}>{children}</ThemeProvider>
    </LocalizationProvider>
  </SettingsProvider>
);
