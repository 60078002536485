import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { createTheme, Theme } from '@mui/material';

export const getCustomTheme = (isLight: boolean) =>
  createTheme({
    palette: isLight ? palette.light : palette.dark,
    typography,
    breakpoints,
    shape: { borderRadius: 6 },
    shadows: isLight ? shadows.light : shadows.dark,
    customShadows: isLight ? customShadows.light : customShadows.dark,
  });

export const getComponentOverrides = (theme: Theme) => componentsOverride(theme);
