import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { missingWrapper } from './styles';
import notFound from '../../assets/404.svg';

export const MissingOrg: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={missingWrapper}>
      <div>
        <Typography variant='h4' className='successHeader'>
          {t('notFoundHeader')}
        </Typography>
        <img className='successImg' src={notFound} alt='success' />
      </div>
    </div>
  );
};
