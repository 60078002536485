import { ThemeAppWrapper } from '@laguna/common/theme';
import { getComponentOverrides, getCustomTheme } from './themeGetters';
import { FCC } from '@laguna/types';
export const LegacyThemeAppWrapper: FCC<{ lockLightTheme?: boolean }> = ({ children, lockLightTheme }) => (
  <ThemeAppWrapper
    lockLightTheme={lockLightTheme}
    getComponentOverrides={getComponentOverrides}
    getCustomTheme={getCustomTheme}>
    {children}
  </ThemeAppWrapper>
);
