import { Stack, Step, StepIconProps, StepLabel, Stepper, Theme, Tooltip, css } from '@mui/material';
import React, { useMemo } from 'react';
import { legacy_palette } from '@laguna/harmony-common/legacyColors';
import { ChoiceOption } from './formTypes';

const gradient = legacy_palette.light.gradients.tertiary;

const getStepperWrapperStyle = (itemCount: number) => (theme: Theme) =>
  css`
    width: ${theme.spacing(itemCount * 4)};
    .MuiStep-root,
    .MuiStepLabel-iconContainer {
      padding: 0;
    }

    .MuiStepConnector-root {
      background: ${gradient};
      opacity: 0.3;
      height: 3px;
      rotate: 180deg;
    }
  `;

const smallCircle = (theme: Theme) => css`
  width: ${theme.spacing(1.5)};
  height: ${theme.spacing(1.5)};
  border-radius: ${theme.spacing(4)};
  background: ${gradient};
  border: solid 2px ${theme.palette.common.white};
  color: ${theme.palette.common.white};
  align-items: center;
  justify-content: center;
  box-shadow: ${theme.shadows[2]};
  transition: box-shadow 0.2s;

  cursor: pointer;
  > * {
    cursor: pointer;
  }

  &:hover {
    box-shadow: ${theme.shadows[5]};
  }
`;
const selected = (theme: Theme) => css`
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
`;

interface ChoiceStepperProps {
  selectedValue: string | number | undefined;
  options: ChoiceOption[];
}

const StepperIcon = ({ value, ...props }: StepIconProps & { value: string | number }) =>
  props.active ? (
    <Stack data-testid='selectedOption' css={[smallCircle, selected]}>
      {value}
    </Stack>
  ) : (
    <Stack css={smallCircle} />
  );

export const ChoiceStepper: React.FC<ChoiceStepperProps> = ({ options, selectedValue }) => {
  const { selectedValueIndex, stepperCSS } = useMemo(
    () => ({
      selectedValueIndex: options.findIndex((option) => option.value.toString() === selectedValue),
      stepperCSS: getStepperWrapperStyle(options.length),
    }),
    [selectedValue, options]
  );

  return (
    <Stepper nonLinear css={stepperCSS} activeStep={selectedValueIndex}>
      {options.map(({ label, value }) => (
        <Step key={value}>
          <Tooltip title={label} placement='top'>
            <StepLabel StepIconComponent={(props) => <StepperIcon {...props} value={value} />} />
          </Tooltip>
        </Step>
      ))}
    </Stepper>
  );
};
