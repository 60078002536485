import { Avatar, AvatarProps, Theme } from '@mui/material';
import { legacy_palette } from '@laguna/harmony-common/legacyColors';
import { css } from '@mui/system';
import { alpha } from '@mui/material/styles';
import { forwardRef } from 'react';

const VIOLET = legacy_palette.light.chart.violet[0];

export const choiceInputWrapper = css`
  display: flex;
  transition: background 0.2s;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

interface ColoredAvatarProps extends AvatarProps {
  selected: boolean;
  disabled?: boolean;
}

const coloredAvatarSelected = (theme: Theme) => css`
  color: ${theme.palette.background.default};
  background: ${VIOLET};
`;

const coloredAvatarDisabled = css`
  cursor: pointer;
`;

const coloredAvatarStyle = (theme: Theme) => css`
  cursor: pointer;
  font-weight: 700;
  color: ${VIOLET};
  background: ${alpha(VIOLET, 0.1)};
  transition: background 0.2s, color 0.2s, transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ColoredAvatar = forwardRef<HTMLDivElement, ColoredAvatarProps>(({ disabled, selected, ...rest }, ref) => (
  <Avatar
    css={[coloredAvatarStyle, disabled && coloredAvatarDisabled, selected && coloredAvatarSelected]}
    ref={ref}
    {...rest}
  />
));

export const gridArea = (gridArea?: string) => css`
  ${gridArea ? `.grid-form & { grid-area: ${gridArea}; }` : ''};
`;

export const SwitchWrapper = (theme: any) => css`
  margin: 0 5px;
  align-items: flex-start;
  div {
    padding-top: ${theme.spacing(1)};
    p {
      margin-top: 0;
    }
  }
`;
