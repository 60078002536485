import { logger } from '@laguna/logger';
import { decryptPusherMessage } from '../decryptPusher/decryptPusherWeb';
import { PusherMessages, SocketHandler } from './types';

export const getDataDecryptWrapper =
  (userId: string, onTranscriptionEvent: (event: PusherMessages) => void, shouldDecrypt: boolean) =>
  (wrappedFunction: SocketHandler) =>
  async (encryptedData: any) => {
    const data: any = shouldDecrypt ? await decryptPusherMessage(encryptedData, userId) : encryptedData;
    if (!data) {
      logger.warn('Error decrypting message', { encryptedData, userId });
      return;
    }
    wrappedFunction(data, onTranscriptionEvent);
  };
