import { Recording } from '@laguna/api';

export type SocketHandler = (data: any, onTranscriptionEvent: (event: PusherMessages) => void) => Promise<void>;

export type RealtimeMessageType = 'mixed' | 'inbound' | 'outbound';

export enum SocketCallEvents {
  TRANSCRIPTION = 'TRANSCRIPTION',
  TRANSCRIPTION_PARTIAL = 'TRANSCRIPTION_PARTIAL',
  KEEP_ALIVE = 'KEEP_ALIVE',
  CALL_ENDED = 'CALL_ENDED',
  CONFERENCE_CREATED = 'CONFERENCE_CREATED',
  VOIP_PARTICIPANT_ADDED = 'VOIP_PARTICIPANT_ADDED',
}

export enum SocketRelayEvents {
  CALL_STARTED = 'CALL_STARTED',
}

export enum SocketAlertEvents {
  NAVIGATION = 'NAVIGATION',
}

export enum SocketServerEvents {
  RECORDING_TRANSCRIPT_SUMMARY_STARTED = 'onRecordingTranscriptSummaryStarted',
  RECORDING_TRANSCRIPT_SUMMARY_COMPLETED = 'onRecordingTranscriptSummaryCompleted',
}

//TODO: change this to the server enum once ready (ClientEventType on argus)
export enum SocketType {
  alert = 'alert',
  subscription = 'subscription',
  transcription = 'transcription',
  relay = 'relay',
  event = 'event',
}

export type CallTranscriptionMessage = {
  name: SocketCallEvents.TRANSCRIPTION_PARTIAL | SocketCallEvents.TRANSCRIPTION;
  text: string;
  payload: {
    type: RealtimeMessageType;
  };
};

export type CallKeepAliveMessage = {
  name: SocketCallEvents.KEEP_ALIVE;
};

export type CallExternalEndMessage = {
  name: SocketCallEvents.CALL_ENDED;
  payload: {
    memberId: string;
    userId: string;
    recordingId: string;
    appointmentId: string;
    recording: Recording;
  };
};

export type AlertNavigateMessage = {
  name: SocketAlertEvents.NAVIGATION;
  payload: {
    memberId: string;
    path: string;
    pathParams?: string;
  };
};

export type RelayCallStartedMessage = {
  name: SocketRelayEvents.CALL_STARTED;
  payload: {
    memberId: string;
    userId: string;
    recordingId: string;
    appointmentId: string;
    recording: Recording;
  };
};

export type TranscriptionSummartStartedMessage = {
  name: SocketServerEvents.RECORDING_TRANSCRIPT_SUMMARY_STARTED;
  payload: {
    recordingId: string;
  };
};

export type TranscriptionSummartCompletedMessage = {
  name: SocketServerEvents.RECORDING_TRANSCRIPT_SUMMARY_COMPLETED;
  payload: {
    recordingId: string;
  };
};

export type PusherMessages =
  | CallTranscriptionMessage
  | CallKeepAliveMessage
  | CallExternalEndMessage
  | AlertNavigateMessage
  | RelayCallStartedMessage
  | TranscriptionSummartStartedMessage
  | TranscriptionSummartCompletedMessage;

export type AlertSocketMessage = {
  payload: any;
  metadata: any;
  subscriptionKey?: string;
};

export type SubscriptionSocketMessage = {
  payload: { id: string };
  metadata: {
    subscriptionKey: string | null;
    invalidate?: boolean;
    operation?: 'created' | 'updated' | 'deleted';
  };
};

export type TranscriptionSocketMessage = {
  event: PusherMessages;
};

export type RelaySocketMessage = {
  event: PusherMessages;
};

export type EventSocketMessage = {
  payload: {
    content: {
      entityName: string;
      entityId: string;
    };
    type: SocketServerEvents;
  };
};

export type SocketMessage =
  | AlertSocketMessage
  | SubscriptionSocketMessage
  | TranscriptionSocketMessage
  | RelaySocketMessage
  | EventSocketMessage;

export const isAlertSocketMessage = (name: SocketType, value: any): value is AlertSocketMessage => {
  return name === SocketType.alert;
};
export const isSubscriptionSocketMessage = (name: SocketType, value: any): value is SubscriptionSocketMessage => {
  return name === SocketType.subscription;
};
export const isTranscriptionSocketMessage = (name: SocketType, value: any): value is TranscriptionSocketMessage => {
  return name === SocketType.transcription;
};
export const isRelaySocketMessage = (name: SocketType, value: any): value is RelaySocketMessage => {
  return name === SocketType.relay;
};
export const isEventSocketMessage = (name: SocketType, value: any): value is EventSocketMessage => {
  return name === SocketType.event;
};
