import { alpha, PaletteColor } from '@mui/material/styles';
function createGradient(color1: string, color2: string) {
  return `linear-gradient(to right, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#EEE6FE',
  light: '#A77AFF',
  main: '#783BF2',
  dark: '#4708C4',
  darker: '#230462',
};

const SECONDARY = {
  lighter: '#E5E5FF',
  light: '#8A8AFF',
  main: '#3030F7',
  dark: '#0707C5',
  darker: '#000066',
};
const INFO = {
  lighter: '#CCEEFF',
  light: '#66CCFF',
  main: '#00A3F5',
  dark: '#0077B2',
  darker: '#004466',
};
const SUCCESS = {
  lighter: '#E7FDF1',
  light: '#90EAB9',
  main: '#00BA56',
  dark: '#006E33',
  darker: '#004C23',
};
const WARNING = {
  lighter: '#FFF8E5',
  light: '#FFDC7A',
  main: '#FFBB05',
  dark: '#B27600',
  darker: '#664300',
};

const ERROR = {
  lighter: '#FECDDD',
  light: '#F981A7',
  main: '#FE0755',
  dark: '#A50D3D',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F6FAFF',
  200: '#F4F6F8',
  300: '#EBF0F5',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#2C3948',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GREY_DARK = {
  0: GREY[900],
  100: GREY[800],
  200: GREY[700],
  300: GREY[600],
  400: GREY[500],
  500: GREY[400],
  600: GREY[300],
  700: GREY[200],
  800: GREY[100],
  900: GREY[0],
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

export const GRADIENTS_PRIMARY_START = '#E941CE';
export const GRADIENTS_PRIMARY_END = '#743CFF';

const convertPaletteToDark = (palette: Omit<PaletteColor, 'contrastText'> & { lighter: string; darker: string }) => {
  return {
    ...palette,
    lighter: palette.darker,
    light: palette.dark,
    main: palette.main,
    dark: palette.light,
    darker: palette.lighter,
  };
};

const GRADIENTS = {
  primary: `linear-gradient(45deg, ${GRADIENTS_PRIMARY_START}, ${GRADIENTS_PRIMARY_END})`,
  secondary: createGradient('#4353E3', '#6A34CB'),
  tertiary: createGradient('#0093FE', '#8D47FF'),
  info: createGradient('#74CAFF', '#1890FF'),
  success: createGradient('#AAF27F', '#54D62C'),
  warning: createGradient('#FFE500', '#FFA800'),
  error: createGradient('#FF70A3', '#FF0800'),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#b4a5fb', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#8DE4FF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#87CBAC', '#A4F7CC', '#C0F2DC'],
  red: ['#F21B3F', '#f44865', '#f7768b', '#f9a3b2', '#fcd1d8'],
  yellow: ['#FFE700', '#FFEF5A', '#fff37f', '#FFF7AE', '#FFF3D6'],
  pink: ['#660056', '#CC00AB', '#E524C6', '#FF7AEA', '#FFE5FB'],
  orange: ['#663000', '#B25400', '#F97B0B', '#FFB87A', '#FFF1E5'],
};

const COMMON = {
  common: { black: '#212226', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[300],
  action: {
    hover: '#2346FF14',
    hoverOpaque: '#f2f6ff',
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    active: GREY[600],
  },
};

export const legacy_palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
      secondary: GREY[100],
      tertiary: `linear-gradient(286deg, #FCECFF 30%, #EAE5FF 100%)`,
    },
    border: { default: GREY[400], light: GREY[300] },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    primary: convertPaletteToDark(COMMON.primary),
    secondary: convertPaletteToDark(COMMON.secondary),
    info: convertPaletteToDark(COMMON.info),
    success: convertPaletteToDark(COMMON.success),
    warning: convertPaletteToDark(COMMON.warning),
    error: convertPaletteToDark(COMMON.error),
    text: { primary: '#fff', secondary: GREY[400], disabled: GREY[500] },
    background: {
      paper: GREY[800],
      default: GREY[700],
      neutral: GREY[900],
      secondary: GREY[700],
      tertiary: `linear-gradient(286deg, #27284E 60%, #403982 100%)`,
    },
    action: { ...COMMON.action, hoverOpaque: '#344366' },
    border: { default: GREY[600], light: GREY[700] },
    divider: GREY[700],
    grey: GREY_DARK,
  },
} as const;
