import { LegacyThemeAppWrapper } from '@laguna/harmony-common/legacy-theme';
import { initTranslations } from '@laguna/translations';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppStyledWrapper } from './AppStyledWrapper';
import { MissingOrg } from './components/MissingOrg';
import { Register } from './components/Register';
import { Success } from './components/Success';

initTranslations('register');

export function App() {
  return (
    <StyledEngineProvider injectFirst>
      <LegacyThemeAppWrapper>
        <AppStyledWrapper>
          <BrowserRouter>
            <Routes>
              <Route path='/success' element={<Success />} />
              <Route path='/missingOrg' element={<MissingOrg />} />
              <Route path='/:orgId' element={<Register />} />
              <Route index element={<Navigate to={'/missingOrg'} />} />
            </Routes>
          </BrowserRouter>
        </AppStyledWrapper>
      </LegacyThemeAppWrapper>
    </StyledEngineProvider>
  );
}
