// ----------------------------------------------------------------------

export const FONT_PRIMARY = 'Inter, sans-serif'; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    lineHeight: '80px',
    fontSize: 64,
  },
  h2: {
    fontWeight: 600,
    lineHeight: '64px',
    fontSize: 48,
  },
  h3: {
    fontWeight: 600,
    lineHeight: '48px',
    fontSize: 32,
  },
  h4: {
    fontWeight: 600,
    lineHeight: '36px',
    fontSize: 24,
  },
  h5: {
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: 16,
  },
  h6: {
    fontWeight: 600,
    lineHeight: '21px',
    fontSize: 14,
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 16,
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: '22px',
    fontSize: 14,
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.7,
  },
  body2: {
    fontWeight: 400,
    lineHeight: '22px',
    fontSize: 14,
  },
  caption: {
    fontWeight: 400,
    lineHeight: '18px',
    fontSize: 12,
  },
  overline: {
    fontWeight: 700,
    lineHeight: '18px',
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 0,
  },
  button: {
    fontWeight: 700,
    lineHeight: '26px',
    fontSize: 15,
    textTransform: 'capitalize',
  },
} as const;

export default typography;
